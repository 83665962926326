import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import {Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, of, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AppConfig } from "src/app/config/app.config";
import { WINDOW } from "src/app/injection-tokens/window/window-token";
import { AuthNextPageService } from "src/app/services/auth/auth-next-page.service";

@Injectable()
export class AuthExpiredInterceptor implements HttpInterceptor {

  constructor(
    private readonly authNextPageService: AuthNextPageService,
    private readonly router: Router,
    private readonly config: AppConfig,
    @Inject(WINDOW) private readonly window: Window
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    // これらのAPIの401エラーはキャッチしない
    const ignoreUrls = [
      this.config.apiConfig.auth.loginUrl.toLowerCase(),
      this.config.apiConfig.auth.isAuthorizedUrl.toLowerCase(),
      this.config.apiConfig.auth.logoutUrl.toLowerCase(),
      this.config.apiConfig.customer.loginUrl.toLowerCase(),
    ];

    return next.handle(request).pipe(
      catchError((res) => {
        if (res.status === 401) {
          const matches = res.url.match(/^(?<scheme>[^:]+):\/\/(?<host>[^\/]+)(?<path>.+)$/i);  // 簡易的なURLのパース（検出したいAPIのURLは単純なので問題無い）
          if (matches !== null) {
            const path = matches.groups?.path?.toLowerCase() || '';
            if (!ignoreUrls.find(x => x.toLowerCase() === path)) {
              // auth api以外で401エラーを検出した場合、現在のURLを保存しログアウトする
              this.authNextPageService.setNextPage(this.window.location.href);
              this.redirectToLogout();
              return of(res);
            }
          }
        }
        return throwError(res);
      })
    );
  }

  private redirectToLogout() {
    this.router.navigate(['auth/logout']);
  }
}

import { Injectable } from '@angular/core';
import { GlobalStorageService } from '../storage/global-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthNextPageService {
  private readonly nextPageStoreKey = 'PublicNextPage';

  constructor(
    private readonly storageService: GlobalStorageService,
  ) { }

  setNextPage(url: string) {
    const regexp = new RegExp('/auth/(login|logout)/?');
    const regexp2 = new RegExp('/home/?');
    const regexp3 = new RegExp('/error/');  // エラー画面への遷移も除外する
    if (!url.match(regexp) && url.match(regexp2) && !url.match(regexp3)) {
      // if (!url.match(regexp) && url.match(regexp2) ) {
      this.storageService.setSessionStorage(
        this.nextPageStoreKey,
        url
      );
    }
  }

  getNextPage(remove: boolean = true): string | null {
    const url = this.storageService.getSessionStorage<string>(this.nextPageStoreKey);
    if (remove) {
      this.storageService.removeSessionStorage(this.nextPageStoreKey);
    }
    return url;
  }
}

import {datadogRum} from '@datadog/browser-rum';
import {environment} from "./environments/environment";
import {DD_VERSION} from "../dd-version";

export const initRum = () =>
  datadogRum.init({
    applicationId: '5a6571a7-206b-4e4b-ab39-4d903d7ab94e',
    clientToken: 'pub56f71856919db67ad2a356dee53a2658',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'ap1.datadoghq.com',
    service: 'rmt-rakuhaya-public',
    env: environment.ddEnv,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: DD_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
  });

import {Inject, Injectable } from '@angular/core';
import { WINDOW } from 'src/app/injection-tokens/window/window-token';

export interface CookieItemOptions {
  expires?: Date;
  domain?: string;
  path?: string;
  secure?: boolean;

  // max-age型のlifetime設定は受け入れない
}

export interface StorageItemOptions {
  expires?: Date;
}

@Injectable({
  providedIn: 'root'
})
export class GlobalStorageService {

  constructor(@Inject(WINDOW) private window: Window) { }


  /**
   * Cookieを設定する
   * @param key
   * @param value
   * @param options
   */
  setCookie<T>(key: string, value: T, options?: CookieItemOptions) {
    this.window.document.cookie = this.makeCookieEntry(key, value, options);
  }

  /**
   * Cookieを取得する
   * @param key
   */
  getCookie<T>(key: string): T | null {
    const values = this.parseCookie(this.window.document.cookie);
    if (values[key]) {
      return JSON.parse(values[key]) as T;
    }
    return null;
  }

  /**
   * Cookieを削除する
   * @param key
   * @param options
   */
  removeCookie(key: string, options?: CookieItemOptions) {
    // パスが異なると別のCookie扱いになるため、optionsを受け入れる必要がある
    options = options || {};
    options.expires = new Date(0);
    const data = this.makeCookieEntry(key, '', options);
    this.window.document.cookie = data;
  }

  /**
   * セッションストレージを設定する
   * @param key
   * @param value
   * @param options
   */
  setSessionStorage<T>(key: string, value: T, options?: StorageItemOptions) {
    const data = this.serializeForStorage(value, options);
    this.window.sessionStorage.setItem(key, data);
  }

  /**
   * セッションストレージを取得する
   * @param key
   */
  getSessionStorage<T>(key: string): T | null {
    const data = this.window.sessionStorage.getItem(key);
    return this.deserializeForStorage(data) as T;
  }

  /**
   * セッションストレージを削除する
   * @param key
   */
  removeSessionStorage(key: string) {
    this.window.sessionStorage.removeItem(key);
  }

  /**
   * ローカルストレージを設定する
   * @param key
   * @param value
   * @param options
   */
  setLocalStorage<T>(key: string, value: T, options?: StorageItemOptions) {
    const data = this.serializeForStorage(value, options);
    this.window.localStorage.setItem(key, data);
  }

  /**
   * ローカルストレージを取得する
   * @param key
   */
  getLocalStorage<T>(key: string): T {
    const data = this.window.localStorage.getItem(key);
    return this.deserializeForStorage(data) as T;
  }

  /**
   * ローカルストレージを削除する
   * @param key
   */
  removeLocalStorage(key: string) {
    this.window.localStorage.removeItem(key);
  }

  /**
   * Cookie用のエントリを生成する
   * @param key
   * @param value
   * @param options
   */
  // tslint:disable-next-line:no-any
  makeCookieEntry(key: string, value: any, options?: CookieItemOptions) {
    // optionsでCookieのオプションを設定できるようにする
    let optionString = '';
    const localOptions = options || {};
    if (localOptions.expires) {
      optionString += '; expires=' + localOptions.expires.toUTCString();
    }
    if (localOptions.path) {
      // pathに設定可能な値かを検証する必要がある（セミコロンが含まれていないか等）
      optionString += '; path=' + localOptions.path;
    } else {
      optionString += '; path=/';
    }
    if (localOptions.domain) {
      // domainに設定可能な値かを検証する必要がある（セミコロンが含まれていないか等）
      optionString += '; domain=' + localOptions.domain;
    }
    if (localOptions.secure) {
      optionString += '; secure';
    }

    return encodeURIComponent(key) + '=' + encodeURIComponent(JSON.stringify(value)) + optionString;
  }

  /**
   * Cookie文字列をパースする
   * @param cookieString
   */
  // tslint:disable-next-line:no-any
  private parseCookie(cookieString: string): { [key: string]: any } {
    const entries = cookieString.split(';');

    // tslint:disable-next-line:no-any
    const values: { [key: string]: any } = [];
    for (let i = 0; i < entries.length; i++) {
      const keyValuePair = entries[i].trim().split('=');
      const key = decodeURIComponent(keyValuePair[0]);
      const value = decodeURIComponent(keyValuePair[1]);
      values[decodeURIComponent(keyValuePair[0])] = decodeURIComponent(keyValuePair[1]);
    }

    return values;
  }

  /**
   * セッションストレージ/ローカルストレージ用に値をオプション付きでシリアライズする
   * @param value
   * @param options
   */
  // tslint:disable-next-line:no-any
  private serializeForStorage(value: any, options?: StorageItemOptions) {
    options = options || {};
    const data = {
      value,
      options: {
        expires: options.expires ? options.expires.getTime() : null
      }
    };

    return JSON.stringify(data);
  }

  /**
   * シリアライズされたデータをデシリアライズし、オプションチェックを行う
   * @param serializedValue
   */
  private deserializeForStorage(serializedValue: string | null) {
    if (!serializedValue) {
      return null;
    }

    const data = JSON.parse(serializedValue);
    if (!data) {
      return null;
    }

    // オプションのチェックを行う
    const now = new Date();
    if (data && data.options) {
      if (data.options.expires && (now > (new Date(data.options.expires)))) {
        return null;
      }
    }

    return ('value' in data) ? data.value : data;
  }
}

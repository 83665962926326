import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {initRum} from "./init-rum";

if (environment.production) {
  enableProdMode();
}

initRum()

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

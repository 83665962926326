import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";
import { ApiErrorCodeConfig } from './type/api-error-code.config';

@Injectable({
  providedIn: 'root'
})
export class AppConfig {

  readonly apiConfig = {
    auth: {
      loginUrl: `${environment.pathBaseApi}/auth/login`,
      logoutUrl: `${environment.pathBaseApi}/auth/logout`,
      isAuthorizedUrl: `${environment.pathBaseApi}/auth/is_authorized`
    },
    customer:{
      entryUrl: `${environment.pathBaseApi}/customer_registration/entry`,
      formSettingUrl: `${environment.pathBaseApi}/customer_registration/form_setting`,
      smsConfirmUrl: `${environment.pathBaseApi}/customer_registration/sms_confirm`,
      anExistCheckUrl: `${environment.pathBaseApi}/customer_registration/an_check`,
      loginUrl: `${environment.pathBaseApi}/customer_registration/login`,
    },
  };

  readonly siteConfig = {
    associationName: 'アールエムトラスト',
    baseTitle: 'アールエムトラスト様本人認証サービス',
    systemName: '利用者画面',
  };

  readonly customerDataConfig = {
    sexTypes : [
      {value: 1, name: '男'},
      {value: 2, name: '女'}
    ],
    identificationDocumentTypes: [
      {value: 1, name: '運転免許証'},
      {value: 2, name: 'マイナンバーカード'},
      {value: 3, name: 'パスポート'},
      {value: 4, name: '外国人登録証'},
    ]
  }

  readonly uploadFileConfig = {
    allowExtension: ['.jpg', '.jpeg'],
    allowMimeType: ['image/jpeg'],
    maxFileSize: 50 * 1024 * 1024 // 50MB
  }

  readonly apiErrorCode: ApiErrorCodeConfig = {
    customer : {
      EntryGeneralFailed: "CRC0100",
      EntryFileUploadFailed: "CRC0101",
      SmsConfirmGeneralFailed: "CRC0200",
      SmsConfirmParameterFailed: "CRC0201",
      SmsConfirmGetTargetFailed: "CRC0202",
      SmsConfirmExpiredFailed: "CRC0203"
    }
  }
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {CommonModule} from "@angular/common";
import {provideInjectionTokens} from "./injection-tokens/injection-tokens";
import {provideHttpInterceptors} from "./http-interceptors/http-interceptors";
import {PlatformModule} from "@angular/cdk/platform";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { LoadingPanelComponent } from './components/shared/loading-panel/loading-panel.component';
import { OverlayModule } from '@angular/cdk/overlay';

@NgModule({
  declarations: [
    AppComponent,
    LoadingPanelComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    PlatformModule,
    BrowserAnimationsModule,
    OverlayModule,
  ],
  providers: [
    provideInjectionTokens(),
    provideHttpInterceptors()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
